/* Animation for content inside the pages */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .stf__block {
    margin-left: -10%;
  }
}
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}

.page img {
  width: 100%;
  height: auto;
}

.page h2 {
  font-size: 24px;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

.language-switcher {
  box-shadow: 0 4px 6px rgb(0 0 0 / 41%), 0 1px 3px rgb(0 0 0 / 46%);
  border-radius: 100px;
  position: fixed;
  top: 24px;
  right: 26px;
  display: inline-block;
  width: 70px; /* 64px * 2 */
  height: 36px;
  transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);
}

.language-switcher input {
  opacity: 0;
  width: 0;
  height: 0;
}

.language-switcher .select-fr,
.language-switcher .select-en {
  position: absolute;
  font-size: 14px; /* 64px / 2.5 */
  top: 16px; /* 64px / 4 */
  color: #fff;
  mix-blend-mode: difference;
}

.language-switcher .select-fr {
  left: 9.5px;
  top: 10px;
}

.language-switcher .select-en {
  right: 8.5px;
  top: 10px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: 0.4s;
  border-radius: 64px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  border: 2px solid #000;
  left: 0;
  bottom: 0;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Moving the slider when checked */
input:checked + .slider:before {
  transform: translateX(33px);
}

input:checked + .slider {
  background-color: #000;
}

/* Hide inactive language text when toggled */
input:checked ~ .select-fr {
  opacity: 1;
  transition: opacity 0.4s;
}

input:not(:checked) ~ .select-en {
  opacity: 1;
  transition: opacity 0.4s;
}

/* .page.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.page.--left {
  border-right: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
} */

/* BUTTON */
html,
body {
  height: 100%;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-ring {
  width: 120px;
  height: 120px;
  border-radius: 100px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 1000px;
  /* box-shadow: 12px 12px 24px rgba(255, 255, 255, 0.64); */
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  font-size: 14px;
  z-index: 10;
}

.button-ring::before {
  content: "";
  border-radius: 1000px;
 width: 120px;
  height: 120px;
  /* border: 6px solid #fff; */
  box-shadow: 0 0 60px rgba(255, 255, 255, 0.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.button-ring:hover,
.button-ring:focus {
  color: #313133;
  transform: translateY(-6px);
}

.button-ring:before,
.button-ring:focus::before {
  opacity: 1;
}

.button-ring::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #fff;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.button-ring:hover::after,
.button-ring:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

.slick-arrow{
  display: none !important;
}

.slick-slide{
  border: 0 !important;
  margin: -2px 0!important;
}

.heart {
	animation: beat .3s infinite alternate;
	transform-origin: center;
}

/* Heart beat animation */
@keyframes beat{
	to { transform: scale(1.1); }
}