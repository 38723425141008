@font-face {
  font-family: "BDC";
  src: url("../../assets/fonts/BeautiqueDisplayCondensed-Italic.otf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "LCS";
  src: url("../../assets/fonts/LCSac\ Trial-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MinionRegular";
  src: url("../../assets/fonts/MinionPro-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MB";
  src: url("../../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MBI";
  src: url("../../assets/fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MM";
  src: url("../../assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.MM {
  font-family: "MM" !important;
}
.MBI {
  font-family: "MBI" !important;
}
.MB {
  font-family: "MB" !important;
}
.MinionRegular {
  font-family: "MinionRegular" !important;
}
.LCS {
  font-family: "LCS" !important;
}
.BDC {
  font-family: "BDC" !important;
}

span,
p,
div {
  font-family: "MM" !important;
}
